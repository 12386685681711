import React, { useState, useEffect } from 'react';

import * as Util from './../data/General';
import ChartRequest from '../components/widget/ChartRequest';
import Filter from '../components/widget/Filter';

function Analytics(props) {
  let isPay =  false; 
  var shopJson =  Util.getAdminShop();
  if (shopJson){
    isPay = Util.isIA(shopJson);
  }

  let startDate = new Date();
  let endDate = new Date();
  startDate.setDate(endDate.getDate() - 360);
  
  const [filter, setFilter] = useState({
    fromNotifyDate: startDate.toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'}) + "T00:00:01",
    toNotifyDate: endDate.toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'})+ "T23:59:59"
  });
  const [url, setUrl] = useState("&fromNotifyDate=" +filter.fromNotifyDate + "&toNotifyDate="+filter.toNotifyDate);
  const [urlSpecial, setUrlSpecial] = useState("&fromDate=" +filter.fromNotifyDate + "&toDate="+filter.toNotifyDate);
  

 

  const updateFilter = (fil) => {
    setFilter(fil);
    setUrl("&fromNotifyDate=" +fil.fromNotifyDate + "&toNotifyDate="+fil.toNotifyDate+"&interval="+fil.interval);
    setUrlSpecial("&fromDate=" +fil.fromNotifyDate + "&toDate="+fil.toNotifyDate+"&interval="+fil.interval);
  }

  useEffect(() => {
   
  },[]);

  const states = {
    "SENT" : {count:0, list:",SENT,APPROVED,REJECTED,EXPIRED,PENDIND_APROVAL,"},
    "APPROVED" : {count:0, list:",APPROVED,"},
    "REJECTED" : {count:0, list:",REJECTED,"},
    "PENDIND_APROVAL" : {count:0, list:",PENDIND_APROVAL,"},
    "APPROVED" : {count:0, list:",APPROVED,"},
    "NEW": {count:0, list:",NEW,"},
    "NOT_SENT": {count:0, list:",NOT_SENT,"}
  };
  return (
    <div>
      <Filter className="anaFilter" setFilter={updateFilter} filter={filter} fromText="fromNotifyDate"  toText="toNotifyDate"/>
      <div className='analytics'>
          <ChartRequest title={Util.getMessage("C_1")} typeValue="int" name={"WhatsApp"} typeLabel="date" delay={10} url={"reviews/intervalstatistics?state=SENT,PENDING_APPROVAL,APPROVED,REJECTED,EXPIRED,ERROR" + url}/>
          <ChartRequest title={Util.getMessage("C_2")} typeValue="int" name={"Reviews"} typeLabel="date" delay={100} url={"reviews/intervalstatistics?state=PENDING_APPROVAL,APPROVED,REJECTED"+ url} />
          <ChartRequest title={Util.getMessage("C_3")} listValues={states} type="bar" pre_label="state_" typeLabel="traductor" name={"Reviews"} delay={300} url={"reviews/statestatistics?s=a" + urlSpecial} />
          <ChartRequest title={Util.getMessage("C_4")} type="pie" listColors={{"MIXED":"#49A0FD","NEUTRAL":"rgb(244, 165, 0)","POSITIVE":"rgb(19, 153, 71)","NEGATIVE":"rgb(253, 87, 84)"}} pre_label="sentiment_" typeLabel="traductor" name={"Reviews"} delay={350} url={"reviews/sentimentstatistics?s=a" + urlSpecial} />
          <ChartRequest title={Util.getMessage("C_5")} type="ListProducts" pre_label="sentiment_" typeLabel="traductor" name={"Reviews"} delay={350}/>
          <ChartRequest title={Util.getMessage("C_6")} type="start" typeLabel="traductor" name={"Reviews"} delay={320} url="?from=0&size=10&sortBy=createdAt&sortOrder=desc&state=PENDING_APPROVAL,APPROVED,REJECTED" />
          <ChartRequest title={Util.getMessage("C_7")} name={"Estrellas"} isZero={false} isAverage={true} typeLabel="date" dataLabels={true} typeValue="fixed2" delay={400} url={"reviews/intervalstarstatistics?state=PENDING_APPROVAL,APPROVED,REJECTED"+ url} />
          <ChartRequest title={Util.getMessage("C_8")} typeLabel="date" yAxis="currency" isZero={false} dataLabels={true} delay={500} typeValue="fixed2" url={"orders/intervalstatistics?utmSource=revie" + urlSpecial} />
          <ChartRequest title={Util.getMessage("C_9")} name={""} isPay={isPay} type="treemap" delay={550} url={"products/tagsstatistics"} />
      </div>
    </div>
);
}

export default Analytics;