import React, { useState, useEffect } from 'react';

import * as Util from '../../data/General';

import { connect } from 'react-redux';

function UpdatePlan(props) {

  const [isShow, setIsShow] = useState(false);
  
  useEffect(() => {
        
    const shop = Util.getAdminShop();
    
    if(shop && shop.plan.planType !== "PLUS"){ 
      setIsShow(true);
    }
  },[]);

  const goToPedding = () => {
    const { dispatch } = props;
    if(dispatch){
      dispatch({
          type: 'CHANGE_MENU',
          new_option: "/plan"
      });
    }
  }
 
  if(isShow){
    return (
      <div className="mt-8 rounded-sm flex gap-1r items-center bg-blue-500 text-white text-sm font-bold px-4 py-3" role="alert">
        <div className="flex items-center gap-4">
          <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/>
          </svg>
          <p>{Util.getMessage("error_plan")} </p>
        </div>
        <a href="#" class="p5-10 me-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={() => goToPedding()}>{Util.getMessage("update_plan")}</a>
      </div>
    );
  }else{
    return null;
  }
}

const mapStateToProps = (state) => ({
    shop: state.shop,
    filter: state.filter
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdatePlan);