import React, { useState, useEffect } from 'react';
import * as Util from './../../data/General';
import Button from '../html/Button';
import ChartRequest from './ChartRequest';
import DashboardCards from './DashboardCards';
import WhatsApp from '../html/WhatsApp';
import CurrencyFormat from 'react-currency-format';
import RememberProm from '../html/RememberProm';
import Filter from './Filter';

export const ADDPOSITIVE = 3;
export const ADDNEGATIVE = -3;
export const MAX_WIDTH = 30;

function Campaign(props) {
  const [style, setStyle] = useState({
    width: 40,
    height: 40
    });

    const { product, isShowProduct, show, hide, handleOk, edit, remember, type, costMens, filter } = props;
    let costMenssage = costMens * (product.campaignResult ? product.campaignResult.total : 0);
    const isOKRates = (sale = null, cost = null) => {
        let salesAmount = product.campaignResult && product.campaignResult.orderTotalPrice ? product.campaignResult.orderTotalPrice : 0;
        let shopify =  Util.getAdminShop();
        let costMen = costMenssage;
        if( cost !== null){
            costMen = cost;
        }
        if (shopify && costMen > 0){
            if(sale !== null){
                salesAmount = sale;
            }
            let campaignResult = product.campaignResult && product.campaignResult.total ? product.campaignResult.total : null; 
            if(campaignResult){
                return (salesAmount/costMen).toFixed(2);
            }
        }
        return 0;
    }

  const [add, setadd] = useState(ADDPOSITIVE);
  const [count, setCount] = useState(0);
  const [isShow, setIsShow] = useState(false);
  const [data, setData] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [isSearchB, setIsSearchB] = useState(false);
  const [delivered, setDelivered] = useState(0);
  const [read, setRead] = useState(0);
  const [wa, setWa] = useState(null);
  const [idWa, setIdWa] = useState(0);
  const [listContSid, setListContSid] = useState(null);
  //const [sales, setSales] = useState(0);
  //const [rates, setRates] = useState(null);
  let roi = isOKRates();
  const date = new Date();
  const [filterState, setFilterState] = useState(null);
  const [total, setTotal] = useState(null);

  const showEfectCount = () => {
    setTimeout(function(){
        setStyle({
            width: style.width + add,
            height: style.height + add
        });
        setCount(count + 1);
    }.bind(this), 1);
    }

    const setHide = () => {
        setadd(ADDNEGATIVE);
        setCount(0);
    }
    const handleError = (data) => {
        console.log(data);
    }

    

    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };

    const getDateFormat = (dateString) =>{
        const event = new Date(dateString);
        return event.toLocaleDateString(window.paramRevie.language, options);
    }

    const getDateFormatHour = (dateString) =>{
        const event = new Date(dateString);
        return new Intl.DateTimeFormat(window.paramRevie.language, { dateStyle: 'full', timeStyle: 'medium' }).format(event);
    }

    const getDateFormatHour2 = (dateString) =>{
        if(dateString){
            const event = new Date(dateString);
            let options = {
                hour: "numeric",
                minute: "numeric",
                timeZone: "America/Mexico_City"
            };
            return new Intl.DateTimeFormat(window.paramRevie.language, options).format(event);
        }
        return "";
    }

    const isOK = (data) => {
        
        if(data && data.code === "200"){
            let contentSid = JSON.parse(data.data);
            setListContSid(contentSid);
            searcContenSid(contentSid);
        }
    }

    

    const searcContenSid = (contentSid) => {
        Object.keys(contentSid).map((item) => {
            if(contentSid[item].id === idWa){
                setWa(contentSid[item]);
            }
        });
    }

    const isError = (error) => {

    }

/*const handleOkUTM = (data) =>{
    if(data && data.totalPrice && data.totalPrice > 0){
        //setSalesAmount(data.totalPrice);
        //setSales(data.mediumCount[0].count);
    }else{
        //Util.getCurrency(isOKRates);
    }
}*/
const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
const handleErrorUTM = (data) =>{
    
}

  useEffect(() => {
    if(!isShowProduct && count === 0){
        let filterDefine = {...Util.FILTERINIC};
        if (window.filters) {    
            //console.log(window.filters[product.id]);
            if(type === "AUTOMATIC" && window.filters[product.id] === undefined && filterDefine.fromDate !== filter.fromDate || filterDefine.toDate !== filter.toDate ){
                window.filters[product.id] = true;
                setRoiFilter("..");
                setOrderCount("..");
                setOrderTotalPrice("..");
                setTimeout(function(){
                    let filterNew = {...filter};
                    filterNew.fromUpdateDate = filterNew.fromDate;  
                    filterNew.toUpdateDate = filterNew.toDate;
                    setFilter(filterNew , true)
                }.bind(this), "500");
            }
        }else{
            window.filters = {}; 
        }
    }
    if(isShowProduct && count < MAX_WIDTH){
        showEfectCount();
    }else if(isShowProduct && count >= MAX_WIDTH && add === ADDPOSITIVE){
        setIsShow(true);
        //Util.getStatisticsCampaigns(product.id, severOk, severError);
        if(!isSearch){
            setIsSearch(true);
            if(!window.intervalSet && "PENDING" === product.state && product.campaignType !== "AUTOMATIC"){
                setupCountdown(".campaign-0", Date.now(), Date.parse(product.notifiedAt));
            }
            if(type !== "NOTIFICATION"){
                Util.getStatistics(dataOk, dataFail, `marketing/campaigns/${product.id}/statusstatistics`);
            }else{
                Util.getStatistics(dataOk, dataFail, `notifications/${product.id}/statusstatistics`);
            }
            //Util.getUtmStatistics(handleOkUTM, handleErrorUTM, `?utmSource=revie&utmMedium=whatsapp&utmCampaign=${product.campaignData.campaign}`);
            //Util.getCurrency(isOKRates);
            //https://api.revie.lat/api/marketing/campaigns/biDTwI8BuDbQmQ1TbRLd/statusstatistics?fromDate=2024-08-11T00:00:01&toDate=2024-09-10T23:59:59
            //https://api.revie.lat/api/orders/utmstatistics?utmSource=revie&utmMedium=whatsapp&utmCampaign=BRI_Winback_28052024_75227_386&fromDate=2024-08-11T00:00:01&toDate=2024-09-10T23:59:59
            let filter = {...Util.filterInic};
            filter.size = 10;
            filter.state = '';
            setFilterState(filter);
            
        }
        if(wa === null && (type === "NOTIFICATION" || (product.campaignData && product.campaignData.campaign))){
            let idMysql = 0;
            if(type !== "NOTIFICATION"){
                idMysql = product.campaignData.campaign.split("_");
                idMysql = idMysql[(idMysql.length - 1)];
            }else{
                console.log("product", product);
                idMysql = product.notificationData.templateId;
            }
            console.log("idMysql", idMysql);
            setIdWa(idMysql);
            if(listContSid === null){
                Util.getContentSid("",isOK, isError);
            }else{
                searcContenSid(listContSid);
            }
        }
        
    }else if(isShowProduct && count >= MAX_WIDTH && add === ADDNEGATIVE){
        hide();
        setadd(ADDPOSITIVE);
        setCount(0);
        setIsShow(false);
        setIdWa(0);
        setData(null);
        setIsSearch(false);
        setWa(null);
        //setSalesAmount(0);
        //setSales(0);
        //setRoi(0);
        //setRates(null);
        setIsSearchB(false);
        //setCostMenssage(0);
        if(window.intervalSet){
            clearInterval(window.intervalSet);
            window.intervalSet = null;
        }
    }
    if(idWa !== 0 && wa === null && listContSid !== null){
        searcContenSid(listContSid);
    }
    /*if(rates === null){
        //Util.getCurrency(isOKRates);
    }*/
  },[count, isShowProduct, idWa, isShow, wa, listContSid, isSearchB]);

  const dataFail = (data) => {

  }

  const dataOk = (data) => {
    setData(data);

    let read = 0;
    let delivered = 0;
    let total = 0;
    if(data){
        data.map((item, key) =>{
            if(item.key === "read"){
                read += item.count; 
            } 
            if(item.key === "delivered" || item.key === "read" || item.key === "received" || item.key === "sent"){
                delivered += item.count; 
            }
            total = total + item.count;
        });
        setRead(read);
        setDelivered(delivered);
        setTotal(total);
        window.total = total;
        if (window.filters) {window.filters[product.id] = total;}
    }
  }

  const deleteCampaign = () => {

    setIsSearchB(true);
    if(type !== "NOTIFICATION"){
        Util.deleteCampaigns(product.id, isOkDeleteCampaign, isOkDeleteCampaign);
    }else{
        Util.deleteNotifications(product.id, isOkDeleteCampaign, isOkDeleteCampaign);
    }
  }

  const isOkDeleteCampaign = (data) => {
    setHide();
    if(handleOk) handleOk();
    setIsSearchB(false);
  }

  const draftCampaign = () => {
    setIsSearchB(true);
    let prd = product;
    prd.state = "DRAFT";
    if(type !== "NOTIFICATION"){
        Util.updateCampaign(prd, isOkDeleteCampaign, isOkDeleteCampaign);
    }else{
        Util.updateNotifications(prd, isOkDeleteCampaign, isOkDeleteCampaign);
    }
  }

  const newRemember = () =>{
    remember();
  }

  const isCreateRemeber = () =>{
    let now= Date.now();
    let max =  Date.parse(product.notifiedAt);
    max = max + (22*60*60*1000);
    if(now < max){
        return true;
    }

    return false;
  }

  const setupCountdown = (campaignSelector, startTimeMillis, endTimeMillis) => {
   var second = 1000;
   var minute = second * 60;
   var hour = minute * 60;
   var day = hour * 24;
  
   function calculateRemaining() {
    var now = new Date().getTime();
    return now >= startTimeMillis && now < endTimeMillis ? endTimeMillis - now : 0;
   }
  
   var previousGap = calculateRemaining();
  
   function countdown() {
    var gap = calculateRemaining();
    
    previousGap = gap;
  
    var textDay = Math.floor(gap / day);
    var textHour = Math.floor((gap % day) / hour);
    var textMinute = Math.floor((gap % hour) / minute);
    var textSecond = Math.floor((gap % minute) / second);
    //console.log((textDay === 0 && textHour === 0 && textMinute === 0 && textSecond === 0));
    if(textDay === 0 && textHour === 0 && textMinute === 0 && textSecond === 0){
        if(window.intervalSet){
            clearInterval(window.intervalSet);
            window.intervalSet = null;
        }
        let hero = document.querySelector(campaignSelector);
        if(hero){
            hero.style.display = "none";
        }
    }else if (document.querySelector(campaignSelector + ' .timer')) {
     document.querySelector(campaignSelector + ' .day').innerText = textDay;
     document.querySelector(campaignSelector + ' .hour').innerText = textHour;
     document.querySelector(campaignSelector + ' .minute').innerText = textMinute;
     document.querySelector(campaignSelector + ' .second').innerText = textSecond;
    }
    
  
    
   }
  
   countdown();
   window.intervalSet = setInterval(function(){
    countdown();
  }, 1000);
  }

  const goToPedding = () => {
    props.dispatch({
        type: 'CHANGE_MENU',
        new_option: "/plan"
    });
    }
    const retry = () => {
        setIsSearchB(true);
        let prd = product;
        
        if(type !== "NOTIFICATION"){
            prd.state = "PENDING";
            Util.updateCampaign(prd, isOkDeleteCampaign, isOkDeleteCampaign);
        }else{
            prd.state = "ACTIVE";
            Util.updateNotifications(prd, isOkDeleteCampaign, isOkDeleteCampaign);
        }
    }
    const getDateMA = () => {
        //date.getFullYear()}-${date.getMonth()}-${date.getDate()
        let y = date.getFullYear();
        let m = date.getMonth();
        if(m === 0){ m = 12; y = y-1; }
        let d = date.getDate();
        if(m < 10) m = "0"+m;
        if(d < 10) d = "0"+d;
        return `${y}-${m}-${d}`;
    }

    const getDateA = () => {
        //date.getFullYear()}-${date.getMonth()}-${date.getDate()
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        let d = date.getDate();
        if(m < 10) m = "0"+m;
        if(d < 10) d = "0"+d;
        return `${y}-${m}-${d}`;
    }
    const[roiFilter, setRoiFilter] = useState(null);
    const[orderCount, setOrderCount] = useState(null);
    const[orderTotalPrice, setOrderTotalPrice] = useState(null);
    const[isFilter, setIsFilter] = useState(false);

    const setFilter = (filter, ischeck = false) =>{
        if(ischeck || isFilter){
            Util.getStatistics(dataOk, dataFail, `marketing/campaigns/${product.id}/statusstatistics?fromDate=${filter.fromUpdateDate}&toDate=${filter.toUpdateDate}`);
            Util.getUtmStatistics(handleOkUTM, handleErrorUTM, `?utmSource=revie&utmMedium=whatsapp&utmCampaign=${product.campaignData.campaign}&fromDate=${filter.fromUpdateDate}&toDate=${filter.toUpdateDate}`);
        }else{
            setIsFilter(true);
        }    
    }

    const isOKRatesSearch = (data) => {
        let shopify =  Util.getAdminShop();
        if (shopify && shopify.countryCode && data[shopify.countryCode]){
            let dat = data[shopify.countryCode];
            if(dat.rate){
                let costMenssage = dat.mkt * dat.rate;
                return costMenssage;      }
        }
    }
    
    const handleOkUTM = (data) =>{
        if(data && data.totalPrice){
            //setSalesAmount(data.totalPrice);
            //setSales(data.mediumCount[0].count);
            let { costMens } = props;
            
            if(costMens === 0){
                costMens = isOKRatesSearch(window.currency);
            }
            let totals = window.total;
            if(window.filters && total === null){
                totals = window.filters[product.id];
            } 
            let costMenssage = costMens * totals;
            roi = isOKRates(data.totalPrice, costMenssage);
            setRoiFilter(roi);
            setOrderCount(data.mediumCount[0].count);
            setOrderTotalPrice(data.totalPrice);
        }else{
            //Util.getCurrency(isOKRates);
            setRoiFilter(0);
            setOrderCount(0);
            setOrderTotalPrice(0);
        }
        if (window.filters) {   window.filters[product.id] = undefined;}
    }

  return (
    <>  
        {isShow && (
            <div>
                <div className='link-ext-product'>
                    <Button type="link" value={Util.getMessage("back_hide_campaign")} imageInc="img/back.png" onClick={() => setHide()}/>
                </div>
                <Filter setFilter={setFilter} filter={filterState} />
            </div>
        )}
        <div onClick={() => {show(product)}} className={`content-review content-product-item ${product.state} ${isShowProduct ? 'show-unic' : ''}`} >
            <div className='content-img-prod'>
                    <div>
                        {product.name}
                    </div>
            </div>
            <div className='content-flex flex-center'>
                {type === "BASIC" ? (
                    <div className={`cont-date ${product.state}`}>{
                        ("PENDING" === product.state || "DRAFT" === product.state) ? 
                        (<div>{Util.getMessage("date_table_2")}: {getDateFormatHour(product.notifiedAt)}</div>)
                        : (<div>{Util.getMessage("date_table_3")}: {getDateFormatHour(product.notifiedAt)}</div>)}
                        <div>{getDateFormat(product.createdAt)}</div>
                    </div>
                ) : type !== "NOTIFICATION" ? (
                    <div className={`cont-date ${product.state}`}>
                        {Util.isNotCHECKOUT(product) ? (
                            <div>
                                <small>{Util.getMessage("confirm_campaign_2_2")} </small>
                                <b>{getDateFormatHour2(product.notifiedAt)}</b>
                            </div>
                        ) : (
                            <div>
                                <b>{Util.getMessage("confirm_campaign_2_2_1").replace("{hour}", product.campaignData.additionalData["checkout.hours.notify"])}</b>
                            </div> 
                        )}
                    </div> 
                ): null}
                <div className='cont-stars cont-state'>{Util.getMessage(type === "BASIC" ? "camp_"+product.state : "camp_2_"+product.state)}</div>
                {!isShow && type !== "NOTIFICATION" ? (
                    <>
                        <div className="cont-total">
                            {product.campaignResult && 
                            product.campaignResult.total ? 
                            (roiFilter ===null ? product.campaignResult.total : total) : "-"}
                        </div>
                        <div className="cont-total">
                            {product.campaignResult && 
                            product.campaignResult.orderCount ? 
                            (orderCount === null ? product.campaignResult.orderCount : orderCount) : "-"}
                        </div>
                        <div className="cont-total cont-total-esp">
                            {product.campaignResult && 
                            product.campaignResult.orderTotalPrice ? 
                            (orderTotalPrice === null ? USDollar.format(product.campaignResult.orderTotalPrice) : (orderTotalPrice === "..") ? ".." : USDollar.format(orderTotalPrice)) : "-"}
                        </div>
                        <div className="cont-total">
                            {roiFilter === null ? roi : roiFilter }
                        </div>
                    </>
                    ) : type === "NOTIFICATION" ? (
                        <>
                            <div className="cont-total">
                                {product.notificationResult && 
                                product.notificationResult.total ? 
                                (roiFilter ===null ? product.notificationResult.total : total) : "-"}
                            </div>
                        </>
                    ) : null}
                {isShow && Util.enabledCampaigns(true) && (
                    <div className='content-btn'>
                        {(product.state !== "PENDING" && !(type === "NOTIFICATION" && product.state === "ACTIVE")) ? null : <Button disabled={isSearchB} loader={isSearchB} value={Util.getMessage("b_2")} onClick={() => draftCampaign()}/>}
                        {(product.state === "PENDING" || product.state === "DRAFT" || (type === "NOTIFICATION" && product.state === "ACTIVE")) ? (
                        <>
                            <Button loader={isSearchB} value={Util.getMessage("edit")} onClick={() => edit()}/> 
                            {product.state === "DRAFT" ? <Button loader={isSearchB} value={Util.getMessage(type === "NOTIFICATION" ? "btn_retry_notifications" : "btn_retry")} onClick={() => retry()}/> : null} 
                        </> 
                        ) : null} 
                        {product.state === "PROCESSED" ? null : <Button disabled={isSearchB} loader={isSearchB} type="primary" value={Util.getMessage("b_1")} onClick={() => deleteCampaign()}/>}
                    </div>
                )}     
            </div>
        </div>
        {isShow && (
            <>
                <div style={{padding: "1rem"}} className={`campaigns ${(product && product.state) ? product.state : ''}`}>
                {(product.campaignResult && product.campaignResult.errorCode && product.state === "ERROR") ? (
                    <>
                        <div className='error-campaing'>
                            {Util.getMessage("error_"+product.campaignResult.errorCode)} 
                            <a href="#" onClick={() => goToPedding()}>{Util.getMessage("update_plan")}</a>
                            <Button loader={isSearchB} value={Util.getMessage("btn_retry")} onClick={() => retry()}/> 
                        </div>
                    </>
                ): null}

                    {product.campaignResult && product.campaignResult.total ? (
                        <div className="home">
                            <div className='content-flex space-between-start mobile-scroll flex-direction-column-mobile'>
                                <DashboardCards title={Util.getMessage("send_table_2")} count={roiFilter ===null ? product.campaignResult.total : total} titleTotal={Util.getMessage("message_text")} textTotal={""}/>
                                <DashboardCards title={Util.getMessage("campaign_delivereds")} count={delivered}  titleTotal={Util.getMessage("message_text")} percentage={((delivered/product.campaignResult.total)*100).toFixed(0)}  textTotal={""}/>
                                <DashboardCards title={Util.getMessage("campaign_reads")} count={read}  titleTotal={Util.getMessage("message_text")} percentage={delivered > 0 ?((read/delivered)*100).toFixed(0) : 0} textTotal={""}/>
                                {product.campaignResult ? (
                                    <>
                                    {/*(product.campaignResult.orderCount > 0)  ? (<DashboardCards title={Util.getMessage("message_text_sales")}  titleTotal={""} count={product.campaignResult.orderCount} percentage={0} textTotal={""}/>) : null*/}
                                    {(roiFilter ===null && product.campaignResult.orderTotalPrice > 0)  ? (<DashboardCards title={Util.getMessage("campaign_sales")}  titleTotal={product.campaignResult.orderCount + " " + Util.getMessage("message_text_sales")} count={USDollar.format(product.campaignResult.orderTotalPrice)} percentage={roi} textTotal={""} addPercentage="x"/>) : null}
                                    {(roiFilter !==null)  ? (<DashboardCards title={Util.getMessage("campaign_sales")}  titleTotal={orderCount + " " + Util.getMessage("message_text_sales")} count={USDollar.format(orderTotalPrice)} percentage={roiFilter} textTotal={""} addPercentage="x"/>) : null}
                                    </>
                                ) : null}

                            </div>
                        </div>
                    ) : null}
                    {type === "NOTIFICATION" ? (
                        <div className="home">
                            <div className='content-flex space-between-start mobile-scroll flex-direction-column-mobile'>
                                <DashboardCards title={Util.getMessage("send_table_2")} count={ product.notificationResult.total} titleTotal={Util.getMessage("message_text")} textTotal={""}/>
                                <DashboardCards title={Util.getMessage("campaign_delivereds")} count={delivered}  titleTotal={Util.getMessage("message_text")} percentage={product.notificationResult.total > 0 ? ((delivered/product.notificationResult.total)*100).toFixed(0) : 0}  textTotal={""}/>
                                <DashboardCards title={Util.getMessage("campaign_reads")} count={read}  titleTotal={Util.getMessage("message_text")} percentage={delivered > 0 ?((read/delivered)*100).toFixed(0) : 0} textTotal={""}/>
                           </div>
                        </div>
                    ) : null}

                    {Util.isShopify(Util.getAdminShop()) && product && product.campaignData  && product.campaignData.campaign && ("PROCESSED" === product.state || product.campaignType === "AUTOMATIC")? (
                        <div className='datail-campaing-link text'>{Util.getMessage("link_marketing_text1_new")} <a className='button primary' href={`https://${Util.getShopDomain()}/admin/marketing/reports/tactic?since=${getDateMA()}&until=${getDateA()}&attributionModel=any_click&utm_campaign_name=${product.campaignData.campaign}&utm_campaign=${product.campaignData.campaign}&utm_campaign_medium=whatsapp&referring_channel=revie`} target='_blank'>{Util.getMessage("link_marketing_link_new")}</a></div>
                    ) : null }
                    <div className='content-flex space-between-start data-campaing'>
                        {("PROCESSED" === product.state || type === "NOTIFICATION") ? (
                            <div className='analytics'>
                                {data && (
                                    <ChartRequest title={""} type="donut" isDataCheck={true} dataCheck={data} listColors={{"read":"#49A0FD","sent":"rgb(244, 165, 0)","read":"rgb(19, 153, 71)","undelivered":"rgb(253, 87, 84)", "queued": "rgb(119, 93, 208)", "failed": "rgb(255, 69, 96)", "delivered": "rgba(73, 160, 253, 0.85)", "error": "rgb(253, 87, 84)"}} typeValue="int2" pre_label="campaign_" typeLabel="traductor" name={"Reviews"} delay={300} url={`marketing/campaigns/${product.id}/statusstatistics`} />
                                )}
                            </div>
                        ) : null}
                        <div className='crate-wa-cont'>
                            <div className='crate-wa'>
                                <div>
                                    <div className='cont-wp'>
                                        {wa && (<WhatsApp message={wa} />)}
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
                {type !== "NOTIFICATION" && (Util.isShopify(Util.getAdminShop()) || type !== "AUTOMATIC") ? (
                    <div className='datail-campaing'>
                            <label>{product.campaignData.segmentName ? Util.getMessage("confirm_campaign_3") : "CSV: "}<b>{product.campaignData.segmentName ? product.campaignData.segmentName : product.campaignData.audienceName}</b></label>
                            <label>{Util.getMessage("confirm_campaign_4")}<b>{product.campaignData.contentSid}</b></label>
                        { (costMenssage && costMenssage > 0) ? (<label>{Util.getMessage("confirm_campaign_6")}<b><CurrencyFormat value={(roiFilter === null ? costMenssage : (costMens * total))} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$'} /></b></label>) : null}
                        {(product.source !== "SHOPIFY_CHECKOUT" && product.campaignType !== "AUTOMATIC" && "PENDING" === product.state) ? (
                            <div id="hero" className="hero connect-page">
                                <div className="container">
                                    <div className="hero-body">
                                    <div className="campaign campaign-0">
                                        <div className="counter timer">
                                        <div className="counter-boxes">
                                            <div className="count-box">
                                            <h1 className="value day">0</h1>
                                            <span>{Util.getMessage("calendar_1")}</span>
                                            </div>
                                            <div className="count-box">
                                            <h1 className="value hour">0</h1>
                                            <span>{Util.getMessage("calendar_2")}</span>
                                            </div>
                                            <div className="count-box">
                                            <h1 className="value minute">0</h1>
                                            <span>{Util.getMessage("calendar_3")}</span>
                                            </div>
                                            <div className="count-box">
                                            <h1 className="value second">0</h1>
                                            <span>{Util.getMessage("calendar_4")}</span>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}   
                        
                    </div>
                ) : null}  
                {(type === "BASIC" && product && product.campaignData && ((("PROCESSED" === product.state) && isCreateRemeber()) || (("PENDING" === product.state)))  && !(product.parentId && product.parentId !== "")) ? (
                    <RememberProm costMens={("PENDING" === product.state) ? 0 : costMenssage} newOk={newRemember}/>
                ) : null }
                
            </>
        )}
    </>
);
}

export default Campaign;