import React, { Component } from 'react';
import CurrencyFormat from 'react-currency-format';
import * as Constants from './../../store/index';
import  * as Util from './../../data/General';


class DashboardCardsBottom extends Component {

    state = {
        isShowExtraInfo: false,
        dateTo: null,
    }

    componentDidMount() {
        const { dateTo } = this.props;
        if (dateTo){
            this.setState({dateTo: dateTo});
        }else{
            const today = new Date();
            const yyyy = today.getFullYear();
            let mm = today.getMonth() + 1; // Months start at 0!
            let dd = today.getDate();

            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;

            this.setState({dateTo: yyyy+ '-' + mm + '-' +dd})
        }
    }


    render() {
        const { title, date, count, amount, dateFrom="2023-03-01", mediumCount } = this.props;
        const { isShowExtraInfo, dateTo } = this.state;
        return(
            <div className="card-home card-home-bottom">
                <div className='content-flex space-between-center'>
                    <div className='content-flex column'>
                        <div className='title'>{title}</div>
                        {date ? <div className='date'>{Constants.getDate(date)}</div> : null}
                    </div>
                    <div className='content-flex flex-center'>
                        {/*<div><img src="img/up.png" /></div>*/}
                        {count && <div className='percentage'>{count}</div>}
                        {amount && (
                            <div className='percentage'>
                                <CurrencyFormat value={amount}  displayType={'text'} decimalScale={2} decimalSeparator="." thousandSeparator={","} prefix={'$'} />
                                <img src={isShowExtraInfo ? 'img/apps.png':'img/grid.png'} alt="mas info" title={Util.getMessage("clic_more_info")} onClick={() => this.setState({isShowExtraInfo: !isShowExtraInfo})}/>
                            </div>
                        )}
                    </div>
                </div>
                {isShowExtraInfo && (
                    <div className='content-flex column discount-link'>
                        {mediumCount && mediumCount.map(
                                (option, index) => 
                                <div key={index} className="text-utm">
                                    <img src="img/satisfaction.png" /><b>{option.count}</b> {Util.getMessage("utm_"+option.key)}
                                </div>
                        )}
                        <hr/>
                        {Util.isShopify(Util.getAdminShop()) ? (
                            <>
                                <div>{Util.getMessage("show_shopify")}: </div>
                                <a href={'https://'+Util.getShopDomain()+'/admin/marketing/reports/campaigns?channel=revie&sortColumn=sales&sortDirection=desc&attributionModel=last_click&since='+dateFrom+'&until='+dateTo} target='_blank'><img src='img/expand-arrows.png' alt="link"/>{Util.getMessage("clic_l_attribution")}</a>
                                <a href={'https://'+Util.getShopDomain()+'/admin/marketing/reports/campaigns?channel=revie&sortColumn=sales&sortDirection=desc&attributionModel=first_click&since='+dateFrom+'&until='+dateTo} target='_blank'><img src='img/expand-arrows.png' alt="link"/>{Util.getMessage("clic_c_attribution")}</a>
                                <a href={'https://'+Util.getShopDomain()+'/admin/marketing/reports/campaigns?channel=revie&sortColumn=sales&sortDirection=desc&attributionModel=last_click_non_direct&since='+dateFrom+'&until='+dateTo} target='_blank'><img src='img/expand-arrows.png' alt="link"/>{Util.getMessage("clic_f_attribution")}</a>
                            </>
                        ) : Util.isTiendaNube(Util.getAdminShop()) ? (
                            <>
                                <a href={'https://'+Util.getAdminShop().domain+'/admin/v2/stats/acquisition'} target='_blank'><img src='img/expand-arrows.png' alt="link"/>{Util.getMessage("clic_l_attribution_tn")}</a>
                            </>
                        ) : null}
                    </div>
                )}
            </div>   
        );}
}
export default DashboardCardsBottom;
