import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as Constants from './../../store/index';
import  * as Util from './../../data/General';
import Button from '../html/Button';

class Table extends Component {

    state ={
        
    }

    goToConfig = () => {
        this.props.dispatch({
            type: 'CHANGE_MENU',
            new_option: "/config"
        });
    }

    render() {
        const { header, body, title, className, isShowMS = false, message = "update_message_new_title" , dataInstall, shop} = this.props;

        return (
            <div className={`content-table no-mobile ${className ? className : ''}`}>
                <div className='title'>
                    <div className='content-flex btton-left items-center'>
                        {title}
                        {className ? <div style={{minHeight: "50px"}}></div> : null}
                    </div>
                </div>
                {!isShowMS ? (
                    <table>
                        <thead>
                            {header && header.map((item, key) => (
                                <th key={key}>{item.label}</th>
                            ))}
                        </thead>
                        <tbody>
                            {body && body.length > 0 && body.map((item, key) => {
                               return (item["state"] !== "EXPIRED_NOT_SENT" && item["state"] !== "EXPIRED" ) ? (
                                    <tr key={key}>
                                        {header && header.map((itemHeader, keyHeader) => (
                                            <td key={keyHeader}>
                                                <div className={itemHeader.className ? itemHeader.className : ''}>
                                                    {itemHeader.subId ? (
                                                        <>{item[itemHeader.id][itemHeader.subId]}</>
                                                    ) : (itemHeader.type && itemHeader.type === "status" ) ? (
                                                        <>{Util.getMessage(Util.STATEREVIEWS[item[itemHeader.id]])}</>
                                                    ) : (itemHeader.type && itemHeader.type === "date" ) ? (
                                                        <>{Constants.getDate(item[itemHeader.id])}</>
                                                    )  : (
                                                        <>{item[itemHeader.id]}</>
                                                    )}
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                ) : null
                            })}
                        </tbody>
                    </table>
                ) : (
                    <div className='sR message-install'>
                        {shop && shop.daysOrders === "" ? (
                            <>
                                <div className='title-never'>{Util.getMessage("update_message_new_title_never")}</div>
                                <div className='text max'>{Util.getMessage("update_message_new_title_never_1")}</div>
                                <div className='text tips normal' dangerouslySetInnerHTML={{__html: `<img src="img/lamp.gif" alt="check" />${Util.getMessage("update_message_new_title_never_2")}`}}/>
                                <Button value={Util.getMessage("gotoconfig")} type="primary" onClick={() => this.goToConfig()}/>
                                <div className='text normal' dangerouslySetInnerHTML={{__html: `${Util.getMessage("update_message_new_title_never_3").replace("{{1}}", Util.addDays(parseInt(shop.daysNotify)))}`}} />
                            </>
                        ) : (
                            <div className='center'>
                                <div className='text max'>{Util.getMessage(message)}</div>
                                <section>
                                    <div class="loader-9">
                                    <svg class="loader-star star1" version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" width="23.172px" height="23.346px" viewBox="0 0 23.172 23.346" >
                                        <polygon fill="#c6ff00" points="11.586,0 8.864,8.9 0,8.9 7.193,14.447 4.471,23.346 11.586,17.84 18.739,23.346 16.77,14.985 23.172,8.9 14.306,8.9" />
                                    </svg>
                                    <svg class="loader-star star2" version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" width="23.172px" height="23.346px" viewBox="0 0 23.172 23.346" >
                                        <polygon fill="#c6ff00" points="11.586,0 8.864,8.9 0,8.9 7.193,14.447 4.471,23.346 11.586,17.84 18.739,23.346 16.77,14.985 23.172,8.9 14.306,8.9" />
                                    </svg>
                                    <svg class="loader-star star3" version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" width="23.172px" height="23.346px" viewBox="0 0 23.172 23.346" >
                                        <polygon fill="#c6ff00" points="11.586,0 8.864,8.9 0,8.9 7.193,14.447 4.471,23.346 11.586,17.84 18.739,23.346 16.77,14.985 23.172,8.9 14.306,8.9" />
                                    </svg>
                                    <svg class="loader-star star4" version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" width="23.172px" height="23.346px" viewBox="0 0 23.172 23.346" >
                                        <polygon fill="#c6ff00" points="11.586,0 8.864,8.9 0,8.9 7.193,14.447 4.471,23.346 11.586,17.84 18.739,23.346 16.77,14.985 23.172,8.9 14.306,8.9" />
                                    </svg>
                                    <svg class="loader-star star5" version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" width="23.172px" height="23.346px" viewBox="0 0 23.172 23.346" >
                                        <polygon fill="#c6ff00" points="11.586,0 8.864,8.9 0,8.9 7.193,14.447 4.471,23.346 11.586,17.84 18.739,23.346 16.77,14.985 23.172,8.9 14.306,8.9" />
                                    </svg>
                                    </div>
                                </section>
                                {!dataInstall.sync ? (
                                    <>
                                        <div className='text-label'><div class="donut"></div> {Util.getMessage("synchronization_false")}</div>
                                    </>
                                ) : (
                                    <>
                                        <div className='text-label'><img src="img/checkinstall.png" alt="check" />{Util.getMessage("synchronization_true")}</div>
                                        {dataInstall.total > 0 && (
                                            <div className='text-label'><img src="img/checkinstall.png" alt="check" /> <div>{Util.getMessage("synchronization_total")}: <span>{dataInstall.total}</span></div></div>
                                        )}

                                        {dataInstall.reviews > 0 ? (
                                            <>
                                                <div className='text-label'><img src="img/checkinstall.png" alt="check" /><div>{Util.getMessage("synchronization_resquest_true")}: <span>{dataInstall.reviews}</span></div></div>
                                                <div className='text-label'><img src="img/checkinstall.png" alt="check" />{Util.getMessage("synchronization_success")}</div>
                                            </>
                                        ) : (
                                            <>
                                                <div className='text-label'><div class="donut"></div>{Util.getMessage("synchronization_resquest_false")}</div>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                )}
                
            </div> 
        );
  }
}
const mapStateToProps = (state) => ({
    lastWeek: state.lastWeek, 
    today: state.today,
    user: state.user,
    filter: state.filter
  });
  
  const mapDispatchToProps = dispatch => {
    return {
      dispatch
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Table);
