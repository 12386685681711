import React, { useState, useEffect } from 'react';
import * as Util from './../data/General';
import WhatsApp from '../components/html/WhatsApp';
import Button from '../components/html/Button';
import CreateWhatsApp from '../components/html/CreateWhatsApp';
import Tabs from '../components/html/Tabs';
import ContentSidIA from './ContentSidIA';


function ContentSid(props) {
    const { setCS, setWA, selectedId=null, setUrl=null, typeTemplate=0, subTypeTemplate=0, vars=null, showButton } = props;
    const [sids, setSids] = useState(null);
    const [sidsExamples, setSidsExamples] = useState(null);
    const [isCreate, setCreate] = useState(false);
    const [selected, setSelected] = useState(null);
    const [edit, setEdit] = useState(null);
    const [search, setSearch] = useState("");
    const [tabs, setTabs] = useState([Util.getMessage("t_all"), Util.getMessage("t_campaigns"), Util.getMessage("t_flows"), Util.getMessage("t_notification"), Util.getMessage("t_examples")]);
    const [type, setType] = useState(typeTemplate);
    const [subType, setSubType] = useState(subTypeTemplate);
    const [isCreateIA, setCreateIA] = useState(false);
    const [waIA, setWAIA] = useState(null);


    const isOK = (data) => {
        
        if(data && data.code === "200"){
            let contentSid = JSON.parse(data.data);
            console.log(contentSid);
            Object.keys(contentSid).map((item) => {
                if(selectedId &&contentSid[item].id === selectedId){
                    let wa = contentSid[item];
                    setSelected(wa);
                    setCS(wa.sid);
                    setWA(wa);
                }
                console.log(contentSid[item].cards);
                if(contentSid[item].cards && contentSid[item].cards !=="" && !Array.isArray(contentSid[item].cards)){
                    contentSid[item].cards = JSON.parse(contentSid[item].cards);
                }
            });
            console.log(contentSid);
            setSids(contentSid);
        }
    }

    const isOKExamples = (data) => {
        if(data && data.code === "200"){
            let contentSid = JSON.parse(data.data);
            setSidsExamples(contentSid);
        }
    }

    const isError = (error) => {

    }

    useEffect(() => {
        if(sids === null){
            Util.getContentSid("",isOK, isError);
            Util.getContentSidExamplesServer("",isOKExamples, isError);
        }
    }, [selected, isCreateIA]);

    const click = (wa) => {
        setSelected(wa);
        setCS(wa.sid);
        setWA(wa);
    }
    const createWA = () => {
        setSelected(null);
        setCS("");
        if(isCreate || type === Util.EXAMPLE_TAB){
            Util.getContentSid("&update=yes",isOK, isError);
            setCreate(false);
            setEdit(null);
            let shopJson = Util.getAdminShop();
            if(shopJson.id === Util.SHOP_ADMIN_ID){
                Util.getContentSidExamplesServer("&update=yes",isOKExamples, isError);
            }
        }else{
            Util.deleteContent(edit.id, updateList, isError)
            setEdit(null);
        }
    }
    const updateList = () => {
        setSelected(null);
        Util.getContentSid("&update=yes",isOK, isError);
    }

    const deleteContent = (wa) => {
        window.confirm(Util.getMessage("confirm_delete_sid") +" "+ wa.name + "?") && Util.deleteContent(wa.id, updateList, isError);
    }

    const handleClickTab = (tab) => {
        setType(tab);
    }

    const setBody = (data, type, extra) => {
        if("Carritos_abandonados" === type || "repeat_customers" === type){//Carrito abandonado
            let name = "checkout";
            if("repeat_customers" === type){
                name = "recurrent";
            }
            let wa = {
                body: data,
                type: type,
                extra: extra,
                mediaurl: "",
                name: name+"-ia" + Math.floor(Math.random() * 100000) + "-" +Math.floor(Math.random() * 9000),
            };
            setWAIA(wa);
            setCreateIA(!isCreateIA);
            setCreate(true)
        }else if("Limited_offer" === type || "Promote_product" === type  || "Personalized" === type){
            let name = "offer";
            if("Promote_product" === type){
                name = "product";
            }else if("Personalized" === type){
                name = "person";
            }
            let wa = {
                body: data,
                type: type,
                extra: extra,
                mediaurl: "",
                name: name+"-ia" + Math.floor(Math.random() * 100000) + "-" +Math.floor(Math.random() * 9000),
            };
            if("Promote_product" === type){
                wa.extra = extra.text;
                wa.mediaurl = extra.image;
            }
            setWAIA(wa);
            setCreateIA(!isCreateIA);
            setCreate(true)
        }
    }


    return (
        <div>
            <div className='content-flex space-between'>
            <span></span>
                <div className='content-flex space-between bt-img-14' style={{gap: "0.5rem"}}>
                    <Button value={Util.getMessage(isCreate ? "Cancel" : "create")}  type="primary" onClick={() => {setCreate(!isCreate); setCreateIA(false)}}/>
                    <Button image="/img/ia/chatgp2.png" value={Util.getMessage("createIA")}  type="primary" onClick={() => {setCreateIA(!isCreateIA); setCreate(false)}}/>
                    {!isCreate ? (<input type="search" value={search} onChange={(e) => setSearch(e.target.value)} placeholder={Util.getMessage("search_products")} className="search-wa" />) : null }
                    
                </div>
                {/*{isCreate ? <span></span> : Util.getMessage("contentSid_table")} 
                 */}
                 
            </div>
            {isCreateIA ? (
            <>
                <ContentSidIA setBody={setBody}/>
            </>
            ) : (!isCreate && !edit) ? (
                <>
                    <Tabs 
                        tabs={tabs} 
                        handleClick={handleClickTab} 
                        className="tabs-sid" 
                        inicial={typeTemplate}
                        subTabs={Util.EXAMPLE_TAB} 
                        listSubTabs={Util.LISTSUBTYPE}
                        handleClickST={setSubType}
                    />
                    <div className='list-messages'>
                        {type !== Util.EXAMPLE_TAB ? ( 
                            <>
                                {sids && Object.keys(sids).map((item, key) => (
                                    <div key={key}>
                                        {(sids[item].view === "1" && (search === "" || sids[item].name.toLowerCase().includes(search.toLowerCase())) && (type === 0 || type === parseInt(sids[item].type) )) ? (
                                            <div className='cont-wa-item'>
                                                <div className='delete' onClick={() => deleteContent(sids[item])}>X</div>
                                                <div className='edit' onClick={() => setEdit(sids[item])}><img src="img/edit.png" /></div>
                                                <WhatsApp message={sids[item]} click={click} isSelected = {selected === sids[item]} isName={true}/>
                                            </div>
                                        ) : null }
                                    </div>
                                ))}
                            </>
                        ) : (   
                            <>  
                                {sidsExamples && Object.keys(sidsExamples).map((item, key) => (
                                    <div key={key}>
                                        {(sidsExamples[item].view === "1" && (search === "" || sidsExamples[item].name.toLowerCase().includes(search.toLowerCase())) && (Util.EXAMPLE_TAB === parseInt(sidsExamples[item].type)) && (subType === 0 || subType === parseInt(sids[item].subtype))) ? (
                                            <div className='cont-wa-item'>
                                                <div className='delete' onClick={() => setEdit(sidsExamples[item])}><img src="img/edit.png" /></div>
                                                <WhatsApp message={sidsExamples[item]} click={setEdit} isSelected = {selected === sidsExamples[item]} isName={true}/>
                                            </div>
                                        ) : null }
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </>
            ) : (
                <CreateWhatsApp oncreate={createWA} setUrl={setUrl} typeTemplate={typeTemplate} editTemplate={edit} waIA={waIA} vars={vars} showButton={showButton}/>
            )}
        </div>
    );
}
    
export default ContentSid;
