import React, { Component } from 'react';
import * as Util from './../../data/General';
import Button from '../html/Button';
import { connect } from 'react-redux';
import Notify from '../html/Notify';
import ProductHook from './ProductHook';

class ListProducts extends Component {

    state ={
        notify: ""
    }

    handleOk = () => {
        const { handleOk } = this.props;

        if (handleOk) {
            handleOk();
        }
    }

    goToPedding = () => {
        this.props.dispatch({
            type: 'CHANGE_MENU',
            new_option: "/reviews"
        });
    }

    
    updateImage = (review) => {
        if (review && review.product && review.product.id){
            Util.updateImage(review.product.id);
        }
    }

    render() {
        const { reviews, title, isShowPoints, titleHtml=false, isShowTitle=true, isPay, show, hide, isShowProduct, shop, filter, setFilterOrder } = this.props;
        const { notify } = this.state;
        let planIA = Util.isIA(shop);
        return (
            <div>
                {isShowTitle && !isShowProduct && (
                    <div className='title'>
                        {titleHtml ? (
                            <div className='content-flex btton-left items-center'>
                                <span dangerouslySetInnerHTML={{__html: titleHtml }} />
                                <Button value={Util.getMessage("show_all")} type="primary" onClick={() => this.goToPedding()}/>
                            </div>
                        ) : (
                            <span>{Util.getMessage(title)}</span>
                        )}
                    </div> 
                )}
                
                {reviews && reviews.length > 0 ? (
                    <div>
                        <div>
                            {!isShowProduct ? (
                                <div>
                                    <div className="content-review content-product-item ">
                                        <div className="content-img-prod">
                                            <div className="content-flex flex-center">{Util.getMessage("Name_table")} <span dangerouslySetInnerHTML={{__html: filter.sortBy === "name" ? `<img src='img/up.png' class="img-b ${filter.sortOrder === "desc" ? 'bup' : 'bdown'}" />` : '' }} /></div>
                                        </div>
                                        <div className="content-flex flex-center">
                                            <div onClick={()=> setFilterOrder("reviewData.avgProductStars")} className="content-flex flex-center">{Util.getMessage("Quality_table")} <span dangerouslySetInnerHTML={{__html: filter.sortBy === "reviewData.avgProductStars" ? `<img src='img/up.png' class="img-b ${filter.sortOrder === "desc" ? 'bup' : 'bdown'}" />` : '' }} /></div>
                                            <div onClick={()=> setFilterOrder("reviewData.countProduct")} className="cont-stars titlecont content-flex flex-center">{Util.getMessage("Reviews_table")} <span dangerouslySetInnerHTML={{__html: filter.sortBy === "reviewData.countProduct" ? `<img src='img/up.png' class="img-b ${filter.sortOrder === "desc" ? 'bup' : 'bdown'}" />` : '' }} /></div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            {reviews.map((item, key) => (
                                <div key={key}>
                                    <ProductHook isShowProduct={isShowProduct} planIA={planIA ? true : false} dispatch={this.props.dispatch} hide={hide} show={show} product={item} isPay={isPay} updateImage={this.updateImage} isShowPoints={isShowPoints} handleOk={() => this.handleOk()} openModal={this.openModal}/>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className='sR'>{Util.getMessage("WITHOUT")} {Util.getMessage(title)}</div>
                )}

                {notify !== "" && (
                    <Notify text={notify} />
                )}
            </div> 
        );
  }
}

const mapStateToProps = (state) => ({
    menuSelected: state.menu.menuSelected,
    menuShow: state.menu.menuShow,
    isMobile: state.system.isMobile,
  });
  
  const mapDispatchToProps = dispatch => {
    return {
      dispatch
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ListProducts);
