import React, { useState, useEffect } from 'react';
import {SUCCESS} from '../components/svg/animate';
import * as Util from '../data/General';
import CreateFlow from './CreateFlow';
import Spreadsheet from './Spreadsheet';
import Button from '../components/html/Button';
import CreateSegmentsIA from './CreateSegmentsIA';
import Tabs from '../components/html/Tabs';
import ListSegments from '../components/html/ListSegments';


function Hearings(props) {

    const [flow, setFlow] = useState(null);
    const [ia, setIA] = useState(null);
    const [show, setShow] = useState(true);
    const [newSegment, setNewSegment] = useState(null);
    const [step, setStep] = useState(1);
    const URL= Util.getUrl_domain();
    const shopJson =  Util.getAdminShop();

    
    const [tabs, setTabs] = useState([Util.getMessage("segments")]);
    

    
    const [tabsSelected, setTabsSelected] = useState(0);
    const [countSegements, setCountSegements] = useState(null);

    const [isChange, setIsChange] = useState(false);
      

    
  
    const newSegmentCreate = (seg, data=null) => {
        setNewSegment(seg);
        setFlow(null);
        setIA(null);
        setShow(false);
        setStep(2);
        setCountSegements(null);
        if(seg){
            calculateSegment(seg.id);
        }
    }
    const setCSV = (data, count) =>{
        // source // SHOPIFY_SEGMENT o CSV
        /*if(data){
            const { newCampaing } = this.state;
            newCampaing.source = "CSV"; 
            this.setState({newCampaing: newCampaing, file: data, fileName: data.name, fileCount: count});
        }*/
        setIsChange(true); //change
        setTimeout(function(){setIsChange(false);}.bind(this), "1000");
    }

    useEffect(() => {
        
        if (shopJson){
            if(shopJson.shopType === "TIENDANUBE" || shopJson.shopType === "VTEX" ){
                setTabs([Util.getMessage("hearings_cvs_title")]);
            }else if(shopJson.shopType === "SHOPIFY"){
                setTabs([Util.getMessage("segments"), Util.getMessage("hearings_cvs_title")]);
            }
        }
      },[newSegment, step]);

    const handleClickTab = (tab) => {
        setTabsSelected(tab);
    }
    const onSegments = (value, name, text) => {

    }

    const calculateSegment = (id) =>{
        Util.getSegmentsCount(segmentOK,segmentError,id);
    }
    const segmentError = (data) =>{}
    const segmentOK = (data) =>{
        if(data && data.totalCount){
            setCountSegements(data.totalCount);
        }
    }
     
    return (
        <div className=''>
            {ia ? (
                <CreateSegmentsIA 
                    cancel={() => setIA(null)} 
                    setNewSegment={newSegmentCreate} 
                    title="hearings_create"
                    sub_title="hearings_create_sub"
                    type="hearings"
                />
            ) : flow ? (
                <CreateFlow 
                    configFlow={flow} 
                    cancel={() => setFlow(null)} 
                    setNewSegment={newSegmentCreate} 
                    title="hearings_create"
                    sub_title="hearings_create_sub"
                    type="hearings"
                    addName="-[REVIE-SEGMENTS]"
                />
            ) : (
                <>{step === 1 ? (
                
                
                
                    <div className='content-reviews'>
                        <div className='title content-flex space-between space-top'>
                            {Util.getMessage("hearings_title")} 
                        </div>
                        <Tabs tabs={tabs} handleClick={handleClickTab}/>
                        <div className='content-hearings'>
                            {(tabsSelected === 0 && shopJson.shopType === "SHOPIFY") ? (
                                <>
                                    <h3>{Util.getMessage("hearings_segments_title")}</h3>
                                    <label>{Util.getMessage("hearings_text")}</label>
                                    <div className='select-flow'>
                                        {Util.HEARINGS && Object.keys(Util.HEARINGS).map((key, index) =>
                                            <article key={index} onClick={() => setFlow(Util.HEARINGS[key])}>
                                                <h2>{Util.getMessage(Util.HEARINGS[key].title)}</h2>
                                                <p>{Util.getMessage(Util.HEARINGS[key].description)}</p>
                                                <div className='content-flex space-between'>
                                                    <img src='/img/ls.png' alt="Shopify" />
                                                    <img src='/img/lw.png' alt="WhatsApp" />
                                                </div>
                                            </article>
                                        )}
                                        <article className='ia' onClick={() => setIA(true)}>
                                            <h2>{Util.getMessage("hearings_ia_title")} <img src='/img/ia/chatgp2.png' alt="Shopify" /></h2>
                                            <p>{Util.getMessage("hearings_ia_description")}</p>
                                            <div className='content-flex space-between'>
                                                <img src='/img/ls.png' alt="Shopify" />
                                                <img src='/img/lw.png' alt="WhatsApp" />
                                            </div>
                                        </article>
                                    </div>
                                    <ListSegments 
                                        name="segmentId" 
                                        value={""} 
                                        onClick={onSegments} 
                                        category="segment"
                                    />
                                </>
                            ) : (
                                <>
                                    <div className='text'>
                                        <Spreadsheet setCSV={setCSV}/>
                                    </div>
                                    {!isChange ? (
                                        <ListSegments 
                                            name="segmentId" 
                                            value={""} 
                                            onClick={onSegments} 
                                            category="list"
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </>
                            )}
                        </div>
                    </div> ) : (
                        <div className='content-flow content-reviews content-hearing'>
                            <div className='title content-flex space-between space-top'>
                                <div className='content-flex flex-start'>
                                    <div dangerouslySetInnerHTML={{__html: SUCCESS}} /> {Util.getMessage("hearings_title_ok")}
                                </div>
                            </div>
                            <div className='content-text-segment'>
                                <div className='text-segment'>
                                    <div>ID:</div>
                                    <div>{newSegment && newSegment.id}</div>
                                </div>
                                <div className='text-segment'>
                                    <div>{Util.getMessage("name_segment")}:</div>
                                    <div><b>{newSegment && newSegment.name}</b></div>
                                </div>
                                <div className='text-segment'>
                                    <div>{Util.getMessage("query_segment")}:</div>
                                    <div><i>{newSegment && newSegment.query}</i></div>
                                </div>
                                {countSegements ? (
                                    <div className='text-segment'>
                                        <div>{Util.getMessage("count_segment")}:</div>
                                        <div><b>{countSegements}</b></div>
                                    </div>
                                ) : null}

                            </div>
                            <a href={`${URL}customers/segments/${newSegment.id}`}  target='_blank'>{Util.getMessage("hearings_details_link")}</a>
                            <p dangerouslySetInnerHTML={{__html: Util.getMessage("hearings_details_ok")}} />
                            <div className='button_campaign'>
                                <Button value={Util.getMessage("Volver")} onClick={() => {setStep(1)}}/>
                            </div>
                        </div>
                    ) }
                    
                </>
            )}
        </div>
    );
}

export default Hearings;
