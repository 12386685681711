import React, { useState, useEffect } from 'react';
import Campaigns from './Campaigns';
import * as Util from '../data/General';
import CreateNotifications from './CreateNotifications';
import UpdatePlan from '../components/html/UpdatePlan';


function Notifications(props) {

    const [flow, setFlow] = useState(null);
    const [show, setShow] = useState(true);
    const [newSegment, setNewSegment] = useState(null);
    const [step, setStep] = useState(1);
    const [data, setData] = useState(null);
    const [listCreate, setListCreate] = useState([]);
    const [enabledPermissions, setEnabledPermissions] = useState(null);

    const [list, setList] = useState(Util.NOTIFICATIONS_CAMPAIGNS);

    const setListCreateData = (list) => {
        setListCreate(list);
        if(Util.isShopify(Util.getAdminShop())){
            if(enabledPermissions === null){    
                if(list && list.length > 0){
                    setEnabledPermissions(true);
                }else{
                    Util.getPermissions("read_fulfillments", handleOkDiscount, handleOkDiscountError);    
                }
            }
        }else{
            setEnabledPermissions(true);
        }
    }
    
    
    const existNotifications = (type) =>{
        let ret = false;
        if(listCreate && listCreate.length > 0){
            listCreate.map((item, index) => {
                if(item && item.notificationType === type && item.state === "ACTIVE"){
                    ret =  true;
                }
            });
        }
        return ret;
    }
    const newSegmentCreate = (seg, data=null) => {
        setData(data);
        setNewSegment(seg);
        setFlow(null);
        setShow(false);
        setStep(2);     
    }

    useEffect(() => {
        if(Util.isTiendaNube(Util.getAdminShop())){
            setList(Util.NOTIFICATIONS_CAMPAIGNS_TN);
        }
    },[]);

    const handleOkDiscount = (data) => {
        if (data && data.enabled){
            setEnabledPermissions(true);
        }else{
            setEnabledPermissions(false);
        }
    }
    const handleOkDiscountError = (data) => {
        console.log(data);
        setEnabledPermissions(false);       
    }

    const createFlowOnclick = (flow) => {
        if(enabledPermissions && Util.enabledCampaigns(true) && !existNotifications(list[flow].name)){ 
            if(list[flow] && !list[flow].config){
                setData(list[flow]);
                setFlow(null);
                setShow(false);
                setStep(2);
                setNewSegment(null);
            }
        }
    }

    const goBack = () => {
        setData(null);
        setFlow(null);
        setShow(true);
        setStep(1);
        setNewSegment(null);
        console.log("goBack");
    }
    
    return (
        <div className='notifications'>
            <UpdatePlan /> 
            {flow ? (
                <CreateNotifications configFlow={flow} cancel={() => setFlow(null)} setNewSegment={newSegmentCreate}/>
            ) : (
                <>
                    {show ? (<div className='content-flow content-reviews'>
                        <div className='title content-flex space-between space-top'>
                            {Util.getMessage("Notification_title")}
                        </div>
                        <label>{Util.getMessage("Notification_text")}</label>
                        <div className="flex items-center justify-center">
                            <div className="grid gap-6 grid-flow-row-new mt-8">
                                {list && Object.keys(list).map((key, index) =>
                                    <div key={index} onClick={() => createFlowOnclick(key)} className={`click relative bg-white py-6 px-6 rounded-2xl w-auto my-4 card1 ${(!enabledPermissions || existNotifications(list[key].name)) ? "disabled" : ""}`}>
                                        <div style={{background: list[key].color}} className=" text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl left-4 -top-6">
                                            <img src={list[key].image} style={{width: "34px"}} alt="icon"/>
                                        </div>
                                        <div className="mt-8">
                                            <p className="text-base font-semibold my-2">{Util.getMessage(list[key].title)}</p>
                                            <div className="flex space-x-2 text-gray-500 text-sm">
                                                <p>{Util.getMessage(list[key].description)}</p> 
                                            </div>
                                            <div className='content-flex space-between'>
                                                <img src='/img/ls.png' alt="Shopify" />
                                                <img src='/img/lw.png' alt="WhatsApp" />
                                            </div>

                                           
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>


                        
                    </div> ) : null }

                    {!enabledPermissions && (
                        <div className='text'>
                            <b>{Util.getMessage("TEXT_ENABLED_FULFILLMENT")} <a style={{color: "blue",textDecoration: "underline"}} href={window.url_server_2+"view/update/?shop="+Util.getMetafieldMyshopify_domain()}>LINK</a></b>
                        </div>
                    )}

                    {step === 1 ? (
                        <div><Campaigns type="NOTIFICATION" show={setShow} setOpenNew={() => setShow(!show)} getList={setListCreateData}/>
                        </div>
                    ) : (
                        <Campaigns type="NOTIFICATION" goBack={goBack} show={setShow} setOpenNew={() => setShow(!show)} dataExtra = {data} segment={newSegment}/>
                    )}
                </>
            )}
        </div>
    );
}

export default Notifications;
