import React, { Component } from 'react';
import './index.css';
import './components/css/App.css';
import './components/css/icofont.min.css';
import PropTypes from 'prop-types';

import Menu from './components/Menu';
import Header from './components/Header';
import Content from './components/Content';

import { connect } from 'react-redux';

import items from './data/menuUser';
import itemsAdmin from './data/menuAdmin';

class App extends Component {
  static propTypes = {
    children: PropTypes.object.isRequired
  };

  render() {
    const { children, menuShow, user, isMenu=true } = this.props;
    return (
      <div className={`App ${menuShow && 'open-menu'}`}>
        <Header />
        {isMenu ? (
          <>
            <Menu items={user.type === "ADMIN" ? itemsAdmin : items}/>
            <Content>
              {children}
            </Content>
          </>  
        ) : (
          <>{children}</>
        )}
        
       
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  menuShow: state.menu.menuShow,
  user: state.user,
});

export default connect(
  mapStateToProps
)(App);
