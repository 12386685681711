import React, { useState, useEffect } from 'react';

import * as Util from './../data/General';
import InputHtml from '../components/html/InputHtml';
import SelectHtml from '../components/html/SelectHtml';
import Button from '../components/html/Button';
import Tabs from '../components/html/Tabs';

function CreateFlow(props) {
    const { configFlow, cancel, addName="-[REVIE-FLOW]",setNewSegment, title="flow_create", sub_title="flow_create_sub", type="flow" } = props;

  const [config, setConfig] = useState(null);
  const [query, setQuery] = useState("");
  const [valide, setValide] = useState(true);  

  const [textQuery, setTextQuery] = useState("");
  const [tabs, setTabs] = useState([]);
  const [tabsSelected, setTabsSelected] = useState(0);

  const [dataExtra, setDataExtra] = useState(null);
  
  useEffect(() => {
    if(!config && !configFlow.tabs){
        setConfig(configFlow.config);
        setData(configFlow.config);
    }else if(!config && configFlow.tabs){
        setConfig(configFlow.tabs[0].config);
        setData(configFlow.tabs[0].config);
        let tabslist = [];
        configFlow.tabs.map((tab, index) => {
            tabslist.push(Util.getMessage(tab.name));
        });
        setTabs(tabslist);
    }
    if(!config && Util.isShopify(Util.getAdminShop())){
        Util.getSegments(isOKSid, isErrorSid);
    }
  },[query]);

  const handleClickTab = (tab) => {
    setTabsSelected(tab);
    setConfig(configFlow.tabs[tab].config);
    setData(configFlow.tabs[tab].config);
   }

  const onChange = (value, name) => {
    config[name].value = value;
    setConfig(config);
    setData(config);
  }

  const onChangeValuesIn = (value, name) => {
    let options = config[name].options;
    let option = null;
    options.map((opt, index) => {
        if(opt.value === value){
            option = opt;
        }
    });
    if(option){
        config[name].value = option.value;
        config[name].valueName = Util.getMessage(option.label);
        if(option.data){
            setDataExtra(option.data);
        }
        if(option.extra){
            Object.keys(option.extra).map((key, index) => {
                config[key].value = option.extra[key];
            });
        }
    setConfig(config);
    setData(config);
    }
  }

  const onChangeLP = (value, valueName, name) => {
    config[name].value = value;
    config[name].valueName = valueName;
    setConfig(config);
    setData(config);
  }


  const setData = (config) => {
    
    let query = convert(configFlow.query, config);
    setQuery(query);

    let text = convert(Util.getMessage(configFlow.text_config), config, true, true);
    setTextQuery(text);

    let valide = true;
    Object.keys(config).map((key, index) => {
        if(config[key].value === "" || config[key].value === null){
            valide = false;
        }
    });
    setValide(valide);
  }

  const convert = (text, config, isText=false, isInic=false) => {
    {config && Object.keys(config).map((key, index) => {
        let inic = (config[key].inic ? config[key].inic : "");
        let finish = (config[key].finish ? config[key].finish : "");
        if(isInic){
            inic="";finish="";
        }
        text = text.replace(`[${config[key].referece ? config[key].referece : key}]`, inic + ((isText && config[key].convertValue && config[key].valueName) ? config[key].valueName : config[key].value) + finish);
        {config[key].aditional && Object.keys(config[key].aditional).map((keyA, indexA) => {
            text = text.replace(`[${keyA}]`, inic + (config[key].value + config[key].aditional[keyA])+finish);
        })}
        {config[key].subtract && Object.keys(config[key].subtract).map((keyA, indexA) => {
            text = text.replace(`[${keyA}]`, inic + (config[key].value - config[key].subtract[keyA])+finish);
        })}
    })}

    return text;
  }

  const [sidsObjet, setSidsObjet] = useState(null);

  const isErrorSid = (data) => {}
  const isOKSid = (data) => {
      console.log(data);
      setSidsObjet(data);
  }

  const newFlow = () => {
    let segmentexist = null;
    sidsObjet && sidsObjet.map((value) =>  {
        if(value.query === query){
            segmentexist = value;
        }
    });
    
    console.log("segmentexist", segmentexist);
    if(segmentexist){
        setNewSegment(segmentexist, dataExtra);
    }else if (Util.isShopify(Util.getAdminShop())){
        let newTextQuery = textQuery;
        if(newTextQuery.startsWith("Este segmento incluye a los clientes")){
            newTextQuery = newTextQuery.replace("Este segmento incluye a los clientes ", "Clientes " );
        }  
        
        let data = {
            name: newTextQuery+addName,
            query: query
        }
        console.log(data);
        Util.newFlow(data, isOk, isError);
    }else{
        setNewSegment({id: "", name: ""}, dataExtra);
    }
  }

  const isOk = (data) => {
    console.log("data", data);
    if(data && data.id && setNewSegment){
        setNewSegment(data, dataExtra);
    }
  }

  const isError = (data) => {
    console.log("data", data);
  }
  
  return (
    <div className="create-flow  content-reviews">
        <div className="title content-flex space-between space-top">{Util.getMessage(title)}</div>
        <label>{Util.getMessage(sub_title)}</label>
        <div className='select-flow'>
            <article >
                <h2>{Util.getMessage(configFlow.title)}</h2>
                <p>{Util.getMessage(configFlow.description)}</p>
                <div className='content-flex space-between'>
                    <img src='/img/ls.png' alt="Shopify" />
                    <img src='/img/lw.png' alt="WhatsApp" />
                </div>
            </article>
        </div>
        <div className='content-config'>
        <p className='text-query'>{textQuery}</p>
        {configFlow.tabs ? (
            <Tabs tabs={tabs} handleClick={handleClickTab}/>
        ) : null }
        {config && Object.keys(config).map((key, index) =>
            <div key={index} className='text'>
                {Util.getMessage(config[key].label)}
                {config[key].type === "input" ? (
                    <InputHtml className="" edit={true} placeholder={config[key].sub_type === "listproduct" ? Util.getMessage(config[key].postfix) : ""} type={config[key].sub_type} name={key} value={config[key].value} onBlur={onChange} onBlurListProduct={onChangeLP} min={config[key].min} max={config[key].max} step={config[key].step}/>
                ) : (config[key].type === "select" && config[key].sub_type && config[key].sub_type === "valuesIn") ? (
                    <SelectHtml className="" type="datetime-local" edit={true} name={key} value={config[key].value} onChange={onChangeValuesIn} isKey={false} valuesIn={config[key].options} defaultText={Util.getMessage(config[key].defaultText)} defaultValue=""/>
                ) : config[key].type === "select" ? (
                    <SelectHtml className="" type="datetime-local" edit={true} name={key} value={config[key].value} onChange={onChange} isKey={false} simpleValues={config[key].options}/>
                ) : null } 
            </div>
        )}
            <div className='button_campaign'>
                <Button value={type === "hearings" ? Util.getMessage("create") : Util.getMessage("btn_continue")} disabled={!valide} type="primary" onClick={() => newFlow()}/>
                <Button value={Util.getMessage("Volver")} onClick={() => {cancel()}}/>
            </div>
        </div>

        
    </div>
);
}

export default CreateFlow;